import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'


Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                pageTitle: 'HS Tarm',
                breadcrumb: [
                    {
                        text: 'Overblik',
                        active: true,
                    },
                ],
                resource: 'Client',
                action: 'read',
            },
        },

        {
            path: '/profile',
            name: 'profile',
            component: () => import('@/views/Profile.vue'),
            meta: {
                pageTitle: 'Profil',
                breadcrumb: [
                    {
                        text: 'Profil',
                        active: true,
                    },
                ],
                resource: 'Client',
                action: 'read',
            },
        },


        {
            path: '/planning',
            name: 'planning',
            component: () => import('@/views/planning/Planning.vue'),
            meta: {
                pageTitle: 'Planlægning',
                resource: 'Planning',
                action: 'read',
            },
        },


        {
            path: '/dayplan',
            name: 'dayplan',
            component: () => import('@/views/dayplan/Dayplan.vue'),
            meta: {
                pageTitle: 'Dagsplan',
                resource: 'Dayplan',
                action: 'read',
            },
        },
        {
            path: '/dayplan/:id',
            name: 'dayplan-task',
            component: () => import('@/views/dayplan/DayplanTask.vue'),
            meta: {
                pageTitle: 'Dagsplan',
                breadcrumb: [
                    {
                        text: 'Dagsplan',
                        to: "/dayplan",
                        active: false,
                    },
                ],
                resource: 'Dayplan',
                action: 'read',
            },
        },


        {
            path: '/overflytning',
            name: 'overflytning',
            component: () => import('@/views/overflytning/Categories.vue'),
            meta: {
                pageTitle: 'Overflytningsordre',
                resource: 'Overflytning',
                action: 'read',
            },
        },

        


        {
            path: '/tasks',
            name: 'tasks',
            component: () => import('@/views/tasks/Tasks.vue'),
            meta: {
                pageTitle: 'Opgaver',
                breadcrumb: [
                    {
                        text: 'Opgaver',
                        active: true,
                    },
                ],
                resource: 'Task',
                action: 'read',
            },
        },
        {
            path: '/tasks/:id',
            name: 'task',
            component: () => import('@/views/tasks/Task.vue'),
            meta: {
                pageTitle: 'Opgave',
                breadcrumb: [
                    {
                        text: 'Opgaver',
                        to: "/tasks",
                        active: false,
                    },
                    {
                        text: 'Opgave',
                        active: true,
                    },
                ],
                resource: 'Task',
                action: 'read',
            },
        },


        {
            path: '/task-types',
            name: 'task-types',
            component: () => import('@/views/task-types/TaskTypes.vue'),
            meta: {
                pageTitle: 'Opgavetyper',
                breadcrumb: [
                    {
                        text: 'Opgavetyper',
                        active: true,
                    },
                ],
                resource: 'TaskType',
                action: 'read',
            },
        },
        {
            path: '/task-types/:id',
            name: 'task-type',
            component: () => import('@/views/task-types/TaskType.vue'),
            meta: {
                pageTitle: 'Opgavetype',
                breadcrumb: [
                    {
                        text: 'Opgavetyper',
                        to: "/task-types",
                        active: false,
                    },
                    {
                        text: 'Opgavetype',
                        active: true,
                    },
                ],
                resource: 'TaskType',
                action: 'read',
            },
        },


        {
            path: '/documentation',
            name: 'documentation',
            component: () => import('@/views/documentation/Documentation.vue'),
            meta: {
                pageTitle: 'Dokumentation',
                breadcrumb: [
                    {
                        text: 'Dokumentation',
                        active: true,
                    },
                ],
                resource: 'Documentation',
                action: 'read',
            },
        },


        //  service-articles
        {
            path: '/service-articles',
            name: 'service-articles',
            component: () => import('@/views/service-articles/ServiceArticles.vue'),
            meta: {
                pageTitle: 'Serviceartikler',
                breadcrumb: [
                    {
                        text: 'Serviceartikler',
                        active: true,
                    },
                ],
                resource: 'Customer',
                action: 'read',
            },
        },
        {
            path: '/service-articles/:id',
            name: 'service-article',
            component: () => import('@/views/service-articles/ServiceArticle.vue'),
            meta: {
                pageTitle: 'Serviceartikel',
                breadcrumb: [
                    {
                        text: 'Serviceartikler',
                        to: "/service-articles",
                        active: false,
                    },
                    {
                        text: 'Serviceartikel',
                        active: true,
                    },
                ],
                resource: 'Customer',
                action: 'read',
            },
        },


        {
            path: '/customers',
            name: 'customers',
            component: () => import('@/views/customers/Customers.vue'),
            meta: {
                pageTitle: 'Kunder',
                breadcrumb: [
                    {
                        text: 'Kunder',
                        active: true,
                    },
                ],
                resource: 'Customer',
                action: 'read',
            },
        },
        {
            path: '/customers/:id',
            name: 'customer',
            component: () => import('@/views/customers/Customer.vue'),
            meta: {
                pageTitle: 'Kunde',
                breadcrumb: [
                    {
                        text: 'Kunder',
                        to: "/customers",
                        active: false,
                    },
                    {
                        text: 'Kunde',
                        active: true,
                    },
                ],
                resource: 'Customer',
                action: 'read',
            },
        },



        {
            path: '/store',
            name: 'store',
            component: () => import('@/views/stores/Store.vue'),
            meta: {
                pageTitle: 'Virksomhed',
                breadcrumb: [
                    {
                        text: 'Virksomhed',
                        active: true,
                    },
                ],
                resource: 'Store',
                action: 'read',
            },
        },
        

        {
            path: '/users',
            name: 'users',
            component: () => import('@/views/user/Users.vue'),
            meta: {
                pageTitle: 'Brugere',
                breadcrumb: [
                    {
                        text: 'Brugere',
                        active: true,
                    },
                ],
                resource: 'User',
                action: 'read',
            },
        },
        {
            path: '/users/:id',
            name: 'user',
            component: () => import('@/views/user/User.vue'),
            meta: {
                pageTitle: 'Bruger',
                breadcrumb: [
                    {
                        text: 'Brugere',
                        to: "/users",
                        active: false,
                    },
                    {
                        text: 'Bruger',
                        active: true,
                    },
                ],
                resource: 'User',
                action: 'manage',
            },
        },
        {
            path: '/modules',
            name: 'modules',
            component: () => import('@/views/Modules.vue'),
            meta: {
                pageTitle: 'Moduler',
                breadcrumb: [
                    {
                        text: 'Moduler',
                        active: true,
                    },
                ],
                resource: 'Admin',
                action: 'manage',
            },
        },



        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                layout: 'full',
                resource: 'Auth',
                action: 'read',
            },
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
                resource: 'Auth',
                action: 'read',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

import store from '@/store'
import { canNavigate } from '@/libs/acl/routeProtection'

router.beforeEach(async (to, _, next) => {
    store.dispatch("app/UPDATE_PAGETITLE", null)

    if (!store.state.userInit) {
        await store.dispatch("INIT_USER")
    }

    var isLoggedIn = store.state.user

    if (!canNavigate(to)) {
        // Redirect to login if not logged in
        // ! We updated login route name here from `auth-login` to `login` in starter-kit
        if (!isLoggedIn) return next({ name: 'login' })

        // If logged in => not authorized
        return next({ name: 'home' })
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        return next({ name: 'home' })
    }

    return next()
})

const DEFAULT_TITLE = 'HS Tarm'
router.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = to.meta.pageTitle || DEFAULT_TITLE
    })
})


export default router
